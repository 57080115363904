import React, { useContext } from 'react';
import { PAGE_INDICES } from '../constants/routing';
import { DigitalFuneralContext } from '../context/DigitalFuneralContext';
import SchemeDetailsPage from './schemeDetailsPage/SchemeDetails';
import BeneficiariesPage from './beneficiariesPage/BeneficiariesPage';
import BeneficiariesSplitPage from './beneficiariesSplitPage/BeneficiariesSplitPage';
import ReviewPage from './reviewPage/ReviewPage';
import SuccessPage from './successPage/successPage';
import PolicyDetailFirstPage from './policyPage/policyDetailFirst';
import PolicyDetailSecondPage from './policyPage/policyDetailSecond';

const renderComponent = (action) => {
    switch (action) {
    case PAGE_INDICES.PERSONAL_DETAILS:
        return <SchemeDetailsPage />;
    case PAGE_INDICES.POLICY_BENEFIT:
        return <PolicyDetailFirstPage />;
    case PAGE_INDICES.POLICY_COVER:
        return <PolicyDetailSecondPage />;
    case PAGE_INDICES.BENEFICIARIES:
        return <BeneficiariesPage />;
    case PAGE_INDICES.BENEFICIARIES_SPLIT:
        return <BeneficiariesSplitPage />;
    case PAGE_INDICES.REVIEW_APPLICATION:
        return <ReviewPage />;
    case PAGE_INDICES.SUCCESS:
        return <SuccessPage />;
    default:
        return null;
    }
};

const Outlet = () => {
    const { f_activePageIndex } = useContext(DigitalFuneralContext);
    return <>{renderComponent(f_activePageIndex)}</>;
};

export default Outlet;
