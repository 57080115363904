import React, { useContext, useState } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../ghana/configs/digital-funeral-configs/config';
import {
    OmFormInputFieldWrapper,
    OmFormDropdownFieldWrapper,
    OmApplicationBeneficiaryDetails,
    OmSideDrawer,
} from '@om/component-library-react';
import OmButton from '../../../../../../../components/forms/OmButton';
import { DigitalFuneralContext } from '../../context/DigitalFuneralContext';
import { isInputValid, areAllInputsInvalid, isUserAbove15YearsOfBirth, isDateInPast, getAgeValue } from '../../../../../ghana/utils/digital-funeral-utils/util';
import { OmdsDatePickerField } from '@mom-omds/react-design-system-components/dist/generated/components';
const referenceRelationship = require('../../constants/refeferenceData/relationships');
const referenceGender = require('../../constants/refeferenceData/genders');

const BeneficiariesDetailsSideDrawer = () => {

    const { isDrawerOpen, toggleDrawer, beneficiaryId, addBeneficiaryToListData, setBeneficiaryId } = useContext(DigitalFuneralContext);

    const [beneficiary, setBeneficiary] = useState({
        firstname: undefined,
        OtherName: undefined,
        Relationship: undefined,
        birth_date: undefined,
        gender: undefined,
        MobileNumber: undefined
    });

    const resetEditBeneficiary = () => { setBeneficiary({firstname: undefined,
        OtherName: undefined,
        Relationship: undefined,
        birth_date: undefined,
        gender: undefined,
        MobileNumber: undefined }); 
    };

    const cancelAndCloseEditing = () => {
        toggleDrawer();
        resetEditBeneficiary();
    };

    const handleChange = (e) => {
        const value = e.detail;
        const input = e.target.name;
        let formattedValue = '';
        if (input === 'MobileNumber') {
            if(value.length < 8){
                setBeneficiary({...beneficiary, [input]: null})
                return;
            }else{
                formattedValue = value.startsWith('+233') ? value : '+233' + value;
            }
        } else if (input === 'birth_date') {
            const _age = getAgeValue(value);
            console.log('AGE::', _age, e.detail);
            if(_age < 18 || _age > 65){
                setBeneficiary({...beneficiary, birth_date: null})
            }else{
                setBeneficiary({...beneficiary, id: beneficiaryId, birth_date: e.detail})
            }
            return;
        }else {
            formattedValue = value;
        }
        setBeneficiary({...beneficiary,id: beneficiaryId,[input]: formattedValue });
    };

    const saveBeneficiary = () => {
        addBeneficiaryToListData(beneficiary);
        setBeneficiaryId();
        resetEditBeneficiary();
        // setBeneficiary({firstname: "", OtherName: "",Relationship: "", birth_date: "",idType: "", id_number: "" })
        toggleDrawer();
    };

    const dataToCheck = () => {
        return {
            OtherName: beneficiary?.OtherName,
            firstname: beneficiary?.firstname,
            Relationship: beneficiary?.Relationship,
            gender: beneficiary?.gender,
            birth_date: beneficiary?.birth_date,
        }
    }


    return (
        <OmSideDrawer
            onOnSideDrawerClosed={() => {
                toggleDrawer();
                resetEditBeneficiary();
            }}
            open={isDrawerOpen}
        >
            <div slot="side-drawer-content">
                <OmApplicationBeneficiaryDetails>
                    <div slot="om-application-beneficiary-details">
                        <div>
                            <h5
                                className="no-top-margin side-drawer-header"
                                id="beneficiaries-side-drawer"
                            >
                                <strong>Add beneficiary details </strong>
                            </h5>

                            <OmFormInputFieldWrapper
                                label="First name"
                                id="firstname"
                                name="firstname"
                                isSensitive
                                labelAlwaysShow={true}
                                required
                                formId="beneficiaryDetailsForm"
                                size="full"
                                align="left"
                                type="text"
                                placeholder="e.g. John"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={beneficiary?.firstname}
                                state={isInputValid(beneficiary?.firstname)}
                                errorMessage={
                                    isInputValid(beneficiary?.firstname) === 'error'
                                        ? 'Please enter first name'
                                        : undefined
                                }
                                onPersist={(e) => handleChange(e)}
                            />
                            <OmFormInputFieldWrapper
                                label="Last name"
                                id="OtherName"
                                name="OtherName"
                                isSensitive
                                labelAlwaysShow={true}
                                required
                                formId="beneficiaryDetailsForm"
                                size="full"
                                align="left"
                                type="text"
                                placeholder="e.g. Doe"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={beneficiary?.OtherName}
                                state={isInputValid(beneficiary?.OtherName)}
                                errorMessage={
                                    isInputValid(beneficiary?.OtherName) === 'error'
                                        ? 'Please enter last name'
                                        : undefined
                                }
                                onPersist={(e) => handleChange(e)}
                            />
                            <OmFormDropdownFieldWrapper
                                id="Relationship"
                                name="Relationship"
                                useNativeMobileDropdown
                                required
                                size="full"
                                formId="beneficiaryDetailsForm"
                                placeholder="Relationship"
                                align="left"
                                type="text"
                                value={beneficiary?.Relationship}
                                state={isInputValid(beneficiary?.Relationship)}
                                errorMessage={
                                    isInputValid(beneficiary?.Relationship) === 'error'
                                        ? 'Please select relationship'
                                        : undefined
                                }
                                options={
                                    referenceRelationship &&
                                    JSON.stringify(
                                        referenceRelationship.RELATIONSHIPS.map((option) => ({
                                            value: option.fullcode,
                                            label: option.description,
                                        }))
                                    )
                                }
                                onOnSelect={(e) =>
                                    setBeneficiary({
                                        ...beneficiary,
                                        Relationship: e.detail.value,
                                    })
                                }
                            />
                            <OmdsDatePickerField
                                label="Date of birth"
                                name='birth_date'
                                value={beneficiary?.birth_date}
                                hasError={isInputValid(beneficiary?.birth_date) === 'error'}
                                onPersist={(e) => handleChange(e)}
                                // onPersist={ (e) => setBeneficiary({...beneficiary, birth_date: e.detail,}) }
                                placeholder="e.g. 1980-05-05"
                            >
                                {isInputValid(beneficiary?.birth_date) === 'error' ? (<span slot="hint-text">{'Date of birth from calender must be between 18 and 65 Years'}</span>) : undefined}
                            </OmdsDatePickerField>

                            <OmFormInputFieldWrapper
                                label="Mobile number"
                                id="MobileNumber"
                                name="MobileNumber"
                                maxLength={13}
                                labelAlwaysShow={true}
                                isSensitive
                                required
                                formId="schemeDetailsForm"
                                size="full"
                                align="left"
                                type="text"
                                placeholder="e.g. +233 54 891 2451"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={beneficiary?.MobileNumber}
                                state={isInputValid(beneficiary?.MobileNumber)}
                                errorMessage={ isInputValid(beneficiary?.MobileNumber) === 'error' ? 'Please enter mobile number.' : undefined }
                                // hintText= 'If Beneficiary is a Minor please provide the Contact Number of Person with Legal Primary Custody and caregiver of the minor'
                                onPersist={(e) => handleChange(e)}
                            />

                            <OmFormDropdownFieldWrapper
                                id="gender"
                                name="gender"
                                useNativeMobileDropdown
                                className="inline-field"
                                required
                                size="full"
                                formId="schemeDetailsForm"
                                placeholder="Gender"
                                align="left"
                                type="text"
                                value={beneficiary?.gender}
                                state={isInputValid(beneficiary?.gender)}
                                errorMessage={isInputValid(beneficiary?.gender) === 'error' ? 'Please select your gender' : undefined }
                                options={
                                    referenceGender &&
                                    JSON.stringify(
                                        referenceGender.GENDERS.map((option) => ({
                                            value: option.fullcode,
                                            label: option.description,
                                        }))
                                    )
                                }
                                onOnSelect={(e) => setBeneficiary({ ...beneficiary, gender: e.detail.value }) }
                            />

                        </div>

                        <div className="action-buttons">
                            <OmButton
                                className="cancel-button"
                                type="text"
                                size="small"
                                text="CANCEL"
                                onClick={() => cancelAndCloseEditing()}
                            />

                            <OmButton
                                disabled={!areAllInputsInvalid(dataToCheck())}
                                className="save-button"
                                type="primary"
                                size="small"
                                text="SAVE BENEFICIARY"
                                onClick={() => saveBeneficiary()}
                            />
                        </div>
                    </div>
                </OmApplicationBeneficiaryDetails>
            </div>
        </OmSideDrawer>
    );
};

export default BeneficiariesDetailsSideDrawer;
