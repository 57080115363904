import React, { useContext } from 'react';
import './successPage.scss';
import { navigate } from '@reach/router';
import {
    OmApplicationThankYou,
    OmApplicationThankYouPage,
    OmThreeColumnIllustration,
    OmApplicationButton
} from '@om/component-library-react';
import checkIcon from '../../assets/images/check.svg';
import desktopIcon from '../../assets/images/desktop.svg';
import mobileAppIcon from '../../assets/images/mobile_app.svg';
import whatsAppIcon from '../../assets/images/whatsapp.svg';
import { DigitalFuneralContext } from '../../context/DigitalFuneralContext';
import OmButton from '../../../../../../../components/forms/OmButton';

const SuccessPage = () => {

    const { funeralData } = useContext(DigitalFuneralContext);

    const closeHander = () => {
        let check  = confirm('Are you sure you want to close this page?')
        if(check){
            if (typeof window !== 'undefined') {
                localStorage.clear();
            }
            navigate('/personal');
        }
    };

    return (
        <div className="mvest-thank-you-page">
            <OmApplicationThankYouPage>
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div  style={{ paddingTop: '2rem'}}>
                        <OmApplicationThankYou
                            // slot="thank-you"
                            imageLink={checkIcon}
                            personName={`${funeralData?.schemeDetails?.first_name} ${funeralData?.schemeDetails?.last_name}`}
                            thankYouCaption={
                                'Thank you for choosing to insure with us. \n' +
                '\n' +
                'We are processing your application.\n A confirmation SMS will be sent shortly. Est. turnaround time: ±5 working days.'
                            }
                            referenceNumber={`${localStorage.getItem('OMDF_policy_no')}`}
                            contactCaption={
                                'If you don’t receive the email, <br /> \n' +
                'please contact us on <strong>0307 000 600</strong>'
                            }
                            // buttonName={""}
                            // buttonLink={""}
                        />
                    </div>
                    <div style={{ marginTop: '-8rem'}}>
                        <OmThreeColumnIllustration
                            // hasBoundary={false}
                            // buttonType="text"

                            imageUrlLeft={desktopIcon}
                            iconNameLeft={'desktop_icon'}
                            redirectUrlLeft={'/'}
                            redirectUrlLeftAndroid={'/'}
                            redirectUrlLeftApple={'/'}
                            subTitleLeft={'Secure services'}
                            paragraphLeft={'Get quick access to your portfolio via our website.'}
                            // buttonTextLeft={button_text_left}
                            // buttonActionTypeLeft={button_action_type_left}
                            // buttonUrlLeft={button_url_left}

                            imageUrlMiddle={mobileAppIcon}
                            iconNameMiddle={'mobile_app_icon'}
                            redirectUrlMiddle={'/'}
                            redirectUrlMiddleAndroid={'/'}
                            redirectUrlMiddleApple={'/'}
                            subTitleMiddle={'Mobile App'}
                            paragraphMiddle={'Check your balances and policies via the App.'}
                            // buttonTextMiddle={button_text_middle}
                            // buttonActionTypeMiddle={button_action_type_middle}
                            // buttonUrlMiddle={button_url_middle}

                            imageUrlRight={whatsAppIcon}
                            iconNameRight={'whatsApp_icon'}
                            redirectUrlRight={'/'}
                            redirectUrlRightAndroid={'/'}
                            redirectUrlRightApple={'/'}
                            subTitleRight={'WhatsApp'}
                            paragraphRight={'Invest, submit a claim, or withdraw via WhatsApp.'}
                            // buttonTextRight={button_text_right}
                            // buttonActionTypeRight={button_action_type_right}
                            // buttonUrlRight={button_url_right}

                            buttonTexts={'TRACK APPLICATION'}
                            buttonUrl={''}
                            buttonActionType={'Call Me Back'}
                            buttonCallMeBackProduct={''}
                            buttonCallMeBackEmail={''}
                            openTab={true}
                        >
                            <h1
                                slot="section-heading"
                                dangerouslySetInnerHTML={{ __html: 'Reach us anywhere, anytime' }}
                            />
                            <span
                                slot="section-caption"
                                dangerouslySetInnerHTML={{
                                    __html: `Keep tabs on your investment wherever you are, whenever you need.`,
                                }}
                            />
                        </OmThreeColumnIllustration>
                    </div>
                    {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "2rem", marginTop: "4rem" }}>
            <div>
              <OmApplicationButton
                text="TRACK APPLICATION"
              />
            </div>
            <div>

           <OmButton
          iconName="arrow_forward"
          slot="right"
          // onClick={}
          text="DONE"
          type="primary"
          size="small"
        />
            </div>


          </div> */}
                </div>
                <OmButton
                    slot="right"
                    onClick={closeHander}
                    text="Close Application"
                    type="primary"
                    size="small"
                />
            </OmApplicationThankYouPage>
        </div>
    );
};

export default SuccessPage;
