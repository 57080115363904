import React, { useContext, useState, useEffect } from 'react';
import OmButton from '../../../../../components/forms/OmButton';
import { OmApplicationWizardFooter } from '@om/component-library-react';
import { DigitalFuneralContext } from './context/DigitalFuneralContext';
import { PAGE_INDICES } from './constants/routing';
import { sendClientRegistration, sendClientBeneficiaries, sendClientCoverMember, checkIfPolicyIsPaid, updatePaymentStatusForQA, hubspotAttachContactToDeal, hubspotCreateDeal, hubspotCreateContact, hubspotGetContact, hubspotUpdateContact } from './server/api';
import { areAllInputsInvalid, generateString, getTotalFuneralCalculationPremium, isInputValid, pushToGAonNext, sumPercentageAllocationValues, travelInsurePremiumDays } from '../../../ghana/utils/digital-funeral-utils/util';
import useUrlParams from './hooks/useUrlParams';


const ApplicationWizardFooter = () => {

    const urlParams = useUrlParams();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {
        f_activePageIndex,
        hasAcceptedTermsAndCondition,
        navigateContinue,
        navigatePrevious,
        funeralData,
        beneficiaryListDataFuneral, coverMemberList, updateFuneralData, togglePaymentModal
    } = useContext(DigitalFuneralContext);

    const continueText = f_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION ? (urlParams?.status_code == 1 ? (isSubmitting ? 'PROCESSING...' : 'SUBMIT & PAY') : 'SUBMIT & PAY') : ( beneficiaryListDataFuneral.length >= 1 && f_activePageIndex === PAGE_INDICES.BENEFICIARIES ? 'CONTINUE' : 'CONTINUE');
    // const continueText = f_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION ? "SUBMIT" : ( beneficiaryListDataFuneral.length >= 1 && f_activePageIndex === PAGE_INDICES.BENEFICIARIES ? "SPLIT PERCENTAGE" : "CONTINUE");
    const isLastPage = f_activePageIndex === PAGE_INDICES.SUCCESS;

    useEffect(() => {
        if(f_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION){
            checkPolicyStatus();
        }    
    },[f_activePageIndex])


    const checkPolicyStatus = async () => {
        updateFuneralData({ loading: false }, 'schemeDetails')
        if(localStorage.getItem('OMDF_policy_no')){
            setIsSubmitting(true)
            
            const resp = await checkIfPolicyIsPaid(localStorage.getItem('OMDF_policy_no'));

            if(resp?.success == true){
                navigateContinue()
            }else{

                if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
                    // update endpoint to STATUS = PAID
                    navigateContinue()
                }else{
                    const queryString = window.location.search;
                    const UP = new URLSearchParams(queryString);

                    if(UP.get('status_code') == 1){
                        const updateResp = await updatePaymentStatusForQA(localStorage.getItem('OMDF_policy_no'))
                        if(updateResp?.success == true){
                            navigateContinue()
                        }else{
                            alert('Problem updating payment status!')
                            setIsSubmitting(false)
                            navigateContinue()
                        }
                    }
                }
                setIsSubmitting(false)
            }
        }
    }

    const validateFormNextStep = () => {
        if (f_activePageIndex === PAGE_INDICES.PERSONAL_DETAILS) {
            let validatePayload = {
                first_name: funeralData?.schemeDetails.first_name,
                last_name: funeralData?.schemeDetails.last_name,
                birth_date: funeralData?.schemeDetails.birth_date,
                gender: funeralData?.schemeDetails.gender,
                mobile_number: funeralData?.schemeDetails.mobile_number,
                email_address: funeralData?.schemeDetails.email_address,
                payment_frequency: funeralData?.schemeDetails.payment_frequency,
                idType: funeralData?.schemeDetails.idType,
                id_number: funeralData?.schemeDetails.id_number,
                momo_number: funeralData?.schemeDetails.momo_number,
            }
            return areAllInputsInvalid(validatePayload);
        }else if (f_activePageIndex === PAGE_INDICES.POLICY_BENEFIT) {
            return isInputValid(funeralData?.schemeDetails?.hasAdditionalBenefit);
        }else if (f_activePageIndex === PAGE_INDICES.POLICY_COVER) {
            // for (let idx = 0; idx < coverMemberList.length; idx++) {
            //     const member = coverMemberList[idx];
            //     return areAllInputsInvalid(member)
            // }
            return true;
        }else if (f_activePageIndex === PAGE_INDICES.BENEFICIARIES) {
            for (let idx = 0; idx < beneficiaryListDataFuneral.length; idx++) {
                const beneficiary = beneficiaryListDataFuneral[idx];
                return areAllInputsInvalid(beneficiary)
            }
        } else if (f_activePageIndex === PAGE_INDICES.BENEFICIARIES_SPLIT) {
            for (let idx = 0; idx < beneficiaryListDataFuneral.length; idx++) {
                const beneficiary = beneficiaryListDataFuneral[idx];

                return areAllInputsInvalid(beneficiary) && sumPercentageAllocationValues(beneficiaryListDataFuneral) == 100
            }
        } else if (f_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION) {
            return hasAcceptedTermsAndCondition;
        }
        return;
    };

    const pay = () => togglePaymentModal()

    const submit = async ()=>{
        try {
            setIsSubmitting(true)
            if(urlParams?.status_code == 3 || urlParams?.status_code == 2){
                pay()
                return;
            }else if(urlParams?.status_code == 1){
                const updateResp = await updatePaymentStatusForQA(localStorage.getItem('OMDF_policy_no'))
                if(updateResp?.success == true){
                    navigateContinue()
                }
                return;
            }

            //=== google analytics ===
            pushToGAonNext('begin_checkout', {currency: 'GHS', value: getTotalFuneralCalculationPremium(funeralData?.schemeDetails?.coverAmtPremium, coverMemberList, funeralData?.schemeDetails?.cashBenefit), items: [{ item_id: generateString(4), item_name: 'Ekyire Asem' }], f_activePageIndex: f_activePageIndex})
           

            if(funeralData?.schemeDetails?.momo_number.length < 10){
                alert('Invalid MoMo number, payment requires valid number eg. 054 891 2451')
                setIsSubmitting(false)
                return;
            }
            updateFuneralData({ loading: true }, 'schemeDetails')
            localStorage.setItem('DF_PA', getTotalFuneralCalculationPremium(funeralData?.schemeDetails?.coverAmtPremium, coverMemberList, funeralData?.schemeDetails?.cashBenefit))
            const res = await sendClientRegistration(funeralData?.schemeDetails)

            if (res?.success == true ){

                localStorage.setItem('OMDF_policy_no', res?.proposal_no)
                
                if(coverMemberList.length > 0){
                    const coverMembers_response = await sendClientCoverMember(res?._token, coverMemberList, res?.proposal_no);

                    if(coverMembers_response?.success == true){
                        const benficiary_response = await sendClientBeneficiaries(res?._token, beneficiaryListDataFuneral, res?.proposal_no)
                        if(benficiary_response?.success == true){
                        // setIsSubmitting(false)

                            // ==== create deal on CRM =====
                            if(process.env.GATSBY_ENVIRONMENT_NAME === 'prod'){
                                await crmDeal(getTotalFuneralCalculationPremium(funeralData?.schemeDetails?.coverAmtPremium, coverMemberList, funeralData?.schemeDetails?.cashBenefit), res?.proposal_no)
                            }
                            // ==== End of creating deal on CRM =====

                            //=== google analytics ===
                            pushToGAonNext('purchase', {currency: 'GHS', value: getTotalFuneralCalculationPremium(funeralData?.schemeDetails?.coverAmtPremium, coverMemberList, funeralData?.schemeDetails?.cashBenefit), items: [{ item_id: generateString(4), item_name: 'Ekyire Asem', transaction_id: res?.proposal_no }], f_activePageIndex: f_activePageIndex})
           
                            updateFuneralData({ loading: false }, 'schemeDetails')
                            pay()
                        }else{
                            updateFuneralData({ loading: false }, 'schemeDetails')
                            setIsSubmitting(false)
                        }
                    }
                }else{
                    const benficiary_response = await sendClientBeneficiaries(res?._token, beneficiaryListDataFuneral, res?.proposal_no)
                    if(benficiary_response?.success == true){
                        //=== google analytics ===
                        pushToGAonNext('purchase', {currency: 'GHS', value: getTotalFuneralCalculationPremium(funeralData?.schemeDetails?.coverAmtPremium, coverMemberList, funeralData?.schemeDetails?.cashBenefit), items: [{ item_id: generateString(4), item_name: 'Ekyire Asem', transaction_id: res?.proposal_no }], f_activePageIndex: f_activePageIndex})
           
                        updateFuneralData({ loading: false }, 'schemeDetails')
                        pay()
                    }else{
                        updateFuneralData({ loading: false }, 'schemeDetails')
                        setIsSubmitting(false)
                        alert('Problem occured while submiitting beneficiary data!')
                    }
                }       
            }else{
                updateFuneralData({ loading: false }, 'schemeDetails')
                setIsSubmitting(false)
                alert('Problem occured while submiitting client registration!')
            }
        } catch (error) {
            updateFuneralData({ loading: false }, 'schemeDetails')
            setIsSubmitting(false)
            console.error('Submission failed:', error);
        }
    }

    const crmContact = async () => {
        if(localStorage.getItem('CRM_CONTACT_ID') == null || localStorage.getItem('CRM_CONTACT_ID') == undefined){
            const _crm = await hubspotGetContact(funeralData?.schemeDetails?.email_address)

            if(!_crm.id){
                let payload = {
                    email: funeralData?.schemeDetails?.email_address,
                    firstname: funeralData?.schemeDetails?.first_name,
                    lastname: funeralData?.schemeDetails?.last_name,
                    phone: funeralData?.schemeDetails?.mobile_number,
                    hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943'
                }
                const createCRMContact = await hubspotCreateContact(payload);
                localStorage.setItem('CRM_CONTACT_ID', createCRMContact?.id)
            }else{
                localStorage.setItem('CRM_CONTACT_ID', _crm?.id)
            }
        }else{
            let payload = {
                email: funeralData?.schemeDetails?.email_address,
                firstname: funeralData?.schemeDetails?.first_name,
                lastname: funeralData?.schemeDetails?.last_name,
                phone: funeralData?.schemeDetails?.mobile_number,
                hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943'
            }
            await hubspotUpdateContact(payload, localStorage.getItem('CRM_CONTACT_ID'))
        }
    }

    const crmDeal = async (amount, policyNumber) => {

        const closeDate = new Date();
        closeDate.setMonth(closeDate.getMonth() + 1);

        let crmPayload = {
            amount: amount,
            closedate: closeDate.toISOString(),
            dealname: `${funeralData?.schemeDetails?.first_name} ${funeralData?.schemeDetails?.last_name} - Ekyire Asem ${policyNumber}`,
            pipeline: 'default',
            dealstage: '376751587',
            hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943',
            dealtype: 'newbusiness',
            plan_name: 'Ekyire Asem',
            source_of_deduction: 'MoMo',
            proposal_policy_number: policyNumber,
            mobilephone: funeralData?.schemeDetails?.momo_number
        }

        const resp = await hubspotCreateDeal(crmPayload)
        // console.log('Deal Resp::', resp);

        // assign contact to deal
        if(resp?.id){
            await hubspotAttachContactToDeal(resp?.id, localStorage.getItem('CRM_CONTACT_ID'))
        } 
    }

    const nextActionButton = () => {
        if(f_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION){
            submit()
        }else{
            pushToGAonNext()
            navigateContinue()
            if(f_activePageIndex === PAGE_INDICES.POLICY_BENEFIT && process.env.GATSBY_ENVIRONMENT_NAME === 'prod'){
                crmContact()
            }
        }
    }


    return (
        !isLastPage && (
            <OmApplicationWizardFooter
                index={f_activePageIndex}
                totalCount="totalSteps"
            >
                <OmButton
                    slot="left"
                    onClick={() => navigatePrevious()}
                    type="text"
                    size="small"
                    text="Previous"
                    disabled={f_activePageIndex <= PAGE_INDICES.PERSONAL_DETAILS}
                />

                <OmButton
                    disabled={!validateFormNextStep() || isSubmitting}
                    // disabled={!validateFormNextStep()}
                    iconName="arrow_forward"
                    slot="right"
                    onClick={() => nextActionButton()}
                    // onClick={() => navigateContinue()}
                    type="primary"
                    size="small"
                    text={continueText}
                />
            </OmApplicationWizardFooter>
        )
    );
};

export default ApplicationWizardFooter;
