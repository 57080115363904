import React, { useContext } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../../ghana/configs/digital-funeral-configs/config';
import {OmFormDropdownFieldWrapper, OmFormInputFieldWrapper, OmRadioButtonGroup, OmSlider, OmModalLoader} from '@om/component-library-react';
import { DigitalFuneralContext } from '../../../context/DigitalFuneralContext';
import { getAgeValue, getFuneralPremium, isInputValid, isValidEmail, stripSystemEmojis, stripZeroPhoneNumber } from '../../../../../../ghana/utils/digital-funeral-utils/util';
import { OmdsDatePickerField } from '@mom-omds/react-design-system-components/dist/generated/components';
import useIdTypeSelection from '../../../hooks/useIdTypeSelection';
const referenceGender = require('../../../constants/refeferenceData/genders');
const referenceIdTypes = require('../../../constants/refeferenceData/idTypes');
const coverSlider = require('../../../constants/refeferenceData/coverAmount');
const agents = require('../../../constants/refeferenceData/agents');

const SchemeDetailsSection = () => {

    const { funeralData, updateFuneralData } = useContext(DigitalFuneralContext);

    const handleChange = (e) => {
        const value = e.detail;
        const input = e.target.name;
        let formattedValue = '';
        
        if (input === 'mobile_number') {
            formattedValue = value.startsWith('233') ? value : '233' + stripZeroPhoneNumber(value);
            if(value.length < 9){
                updateFuneralData({ [input]: null }, 'schemeDetails');
                return;
            }
        } else if (input === 'id_number') {
            const strippedValue = value.replace(/-/g, '');
            if (funeralData?.schemeDetails?.idType === 'Ghana Card Number') {
                formattedValue = strippedValue.replace(/^(\w{3})(\w{9})(\w)$/, '$1-$2-$3');
            } else if (funeralData?.schemeDetails?.idType === 'Voter’s card') {
                formattedValue = value;
            } else {
                formattedValue = value;
            }
        }else if(input === 'first_name' || input === 'last_name'){
            if(!isInputValid(value) || value.trim().length < 2){
                updateFuneralData({ [input]: null }, 'schemeDetails');
                return;
            }
            formattedValue = stripSystemEmojis(value);
        }else if(input === 'email_address'){
            if(!isValidEmail(value)){
                updateFuneralData({ [input]: null }, 'schemeDetails');
                return;
            }
            formattedValue = stripSystemEmojis(value);
        }else if(input === 'birth_date'){
            if(getAgeValue(value) < 18 || getAgeValue(value) > 65){
                updateFuneralData({ [input]: null }, 'schemeDetails');
                return;
            }else{
                // console.log('birth date::',value, getAgeValue(value))
                updateFuneralData({ [input]: value}, 'schemeDetails');
                updateFuneralData({coverAmtPremium: getFuneralPremium(funeralData?.schemeDetails?.coverAmt, getAgeValue(value),funeralData?.schemeDetails?.hasAdditionalBenefit == 'Yes') }, 'schemeDetails');
            }
            formattedValue = value;
        }else if(input === 'coverAmt'){
            if(value < 2000){
                updateFuneralData({ [input]: null }, 'schemeDetails');
                return;
            }
            updateFuneralData({ coverAmtPremium: getFuneralPremium(value, getAgeValue(funeralData?.schemeDetails?.birth_date),funeralData?.schemeDetails?.hasAdditionalBenefit === 'Yes') }, 'schemeDetails')
            formattedValue = stripSystemEmojis(value);
        }else {
            formattedValue = stripSystemEmojis(value);
        }
        updateFuneralData({ [input]: formattedValue }, 'schemeDetails');
    }

    return (
        <>
            <OmFormInputFieldWrapper
                label="First name"
                id="first_name"
                name="first_name"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. John"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={funeralData?.schemeDetails?.first_name}
                state={isInputValid(funeralData?.schemeDetails?.first_name)}
                errorMessage={isInputValid(funeralData?.schemeDetails?.first_name) === 'error' ? 'Please enter your first name' : undefined }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormInputFieldWrapper
                label="Surname"
                id="last_name"
                name="last_name"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. Doe"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={funeralData?.schemeDetails?.last_name}
                state={isInputValid(funeralData?.schemeDetails?.last_name)}
                errorMessage={ isInputValid(funeralData?.schemeDetails?.last_name) === 'error' ? 'Please enter your last name' : undefined }
                onPersist={(e) => handleChange(e)}
            />
            <span className='ekyire-asem-main'>
                <OmdsDatePickerField
                    label="Date of birth"
                    name="birth_date"
                    value={funeralData?.schemeDetails?.birth_date}
                    hasError={isInputValid(funeralData?.schemeDetails?.birth_date) === 'error'}
                    onPersist={(e) => handleChange(e)}
                    placeholder="e.g. 1980-05-05">
                    {isInputValid(funeralData?.schemeDetails?.birth_date) === 'error' || 
                (getAgeValue(funeralData?.schemeDetails?.birth_date) < 18 || getAgeValue(funeralData?.schemeDetails?.birth_date) > 65) ?
                        (<span slot="hint-text">{'You must be at least 18 Years Of Age and not more than 65 Years'}</span>) : '' }
                </OmdsDatePickerField>
            </span>

            <OmFormDropdownFieldWrapper
                id="gender"
                name="gender"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Gender"
                align="left"
                type="text"
                value={funeralData?.schemeDetails?.gender}
                state={isInputValid(funeralData?.schemeDetails?.gender)}
                errorMessage={isInputValid(funeralData?.schemeDetails?.gender) === 'error' ? 'Please select your gender' : undefined }
                options={
                    referenceGender &&
                JSON.stringify(
                    referenceGender.GENDERS.map((option) => ({
                        value: option.fullcode,
                        label: option.description,
                    }))
                )
                }
                onOnSelect={(e) => updateFuneralData({ gender: e.detail.value }, 'schemeDetails') }
            />

            <OmFormInputFieldWrapper
                label="Email Address"
                id="email_address"
                name="email_address"
                labelAlwaysShow={true}
                type="email"
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                keyboardMode="email"
                placeholder="e.g. johndoe@gmail.com"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={funeralData?.schemeDetails?.email_address}
                state={isInputValid(funeralData?.schemeDetails?.email_address)}
                errorMessage={isInputValid(funeralData?.schemeDetails?.email_address) === 'error' ? 'Please enter a valid email address' :  undefined }
                onPersist={(e) => handleChange(e)}
            />
            
            <OmFormInputFieldWrapper
                label="Mobile number"
                id="mobile_number"
                name="mobile_number"
                maxLength={12}
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. +233 54 891 2451"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={funeralData?.schemeDetails?.mobile_number}
                state={isInputValid(funeralData?.schemeDetails?.mobile_number)}
                errorMessage={ isInputValid(funeralData?.schemeDetails?.mobile_number) === 'error' ? 'Please enter your mobile number.' : undefined }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormInputFieldWrapper
                label="Mobile Money Number (MTN or Telecel)"
                id="momo_number"
                name="momo_number"
                maxLength={10}
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. 054 891 2451"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={funeralData?.schemeDetails?.momo_number}
                state={isInputValid(funeralData?.schemeDetails?.momo_number)}
                errorMessage={ isInputValid(funeralData?.schemeDetails?.momo_number) === 'error' ? 'Please enter your mobile number.' : undefined }
                onPersist={(e) => handleChange(e)}
            />

            <p>Select your Cover Amount: </p>
            <OmSlider
                stepString={JSON.stringify(coverSlider.COVER_SLIDER_AMOUNTS)}
                minLabel="₵2,000"
                maxLabel="₵20,000"
                name="coverAmt"
                value={funeralData?.schemeDetails?.coverAmt}
                valuePrefix="₵"
                onSliderChange={(e) => handleChange(e)}
            />

            <OmRadioButtonGroup
                id="payment_frequency"
                vertical={true}
                groupName="payment_frequency"
                value={funeralData?.schemeDetails?.payment_frequency}
                onOnSelection={(e) => updateFuneralData({ payment_frequency: e.detail }, 'schemeDetails')}
                label="Please select how frequently you’ll like to pay"
                labelAlwaysShow={true}>
                <ul slot="list-items">
                    <li value="Monthly">Monthly</li>
                    <li value="Yearly">Yearly</li>
                </ul>
            </OmRadioButtonGroup>

            
            <OmFormDropdownFieldWrapper
                id="idType"
                name="idType"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="ID Type"
                align="left"
                type="text"
                value={funeralData?.schemeDetails?.idType}
                state={isInputValid(funeralData?.schemeDetails?.idType)}
                errorMessage={ isInputValid(funeralData?.schemeDetails?.idType) === 'error' ? 'Please select your ID Type': undefined }
                options={
                    referenceIdTypes &&
                JSON.stringify(
                    referenceIdTypes.ID_TYPES.map((option) => ({
                        value: option.fullcode,
                        label: option.description,
                    }))
                )
                }
                onOnSelect={(e) =>
                    updateFuneralData({ idType: e.detail.value }, 'schemeDetails')
                }
            />

            <OmFormInputFieldWrapper
                label={useIdTypeSelection(referenceIdTypes, funeralData)?.label}
                id="id_number"
                name="id_number"
                isSensitive
                labelAlwaysShow={true}
                maxLength={useIdTypeSelection(referenceIdTypes, funeralData)?.maxLength}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder={useIdTypeSelection(referenceIdTypes, funeralData)?.placeholder}
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={funeralData?.schemeDetails?.id_number}
                state={isInputValid(funeralData?.schemeDetails?.id_number)}
                errorMessage={useIdTypeSelection(referenceIdTypes, funeralData)?.validator() ? useIdTypeSelection(referenceIdTypes, funeralData)?.errorMessage : undefined}
                onPersist={(e) => handleChange(e)}
            />

            <OmFormDropdownFieldWrapper
                id="agentCode"
                name="agentCode"
                useNativeMobileDropdown
                className="inline-field"
                size="full"
                formId="schemeDetailsForm"
                placeholder="Agent Code (Optional)"
                align="left"
                type="text"
                value={funeralData?.schemeDetails?.agentCode}
                options={
                    agents &&
                JSON.stringify(
                    agents.AGENTS.map((option) => ({
                        value: option.name,
                        label: `${option.AgentNoCode} (${option.name})`,
                    }))
                )
                }
                onOnSelect={(e) => updateFuneralData({ agentCode: e.detail.value }, 'schemeDetails') }
            />
        
            <OmModalLoader
                slot="loading-modal"
                loaderText="Please wait..."
                verticalAlignMiddle={true}
                open={funeralData?.schemeDetails?.loading}
            />
        </>
    );
};

export default SchemeDetailsSection;
