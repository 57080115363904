import React, { useContext, useState, useEffect } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../configs/digital-funeral-configs/config';
import {
    OmFormInputFieldWrapper,
    OmFormDropdownFieldWrapper,
    OmApplicationBeneficiaryDetails,
    OmSideDrawer,OmSlider, OmApplicationSummaryDetailsCard
} from '@om/component-library-react';
import OmButton from '../../../../../../../components/forms/OmButton';
import { DigitalFuneralContext } from '../../context/DigitalFuneralContext';
import { isInputValid, areAllInputsInvalid, getTotalFuneralCalculationPremium, getFuneralPremium, getAgeValue } from '../../../../utils/digital-funeral-utils/util';
import { OmdsDatePickerField } from '@mom-omds/react-design-system-components/dist/generated/components';
const referenceRelationship = require('../../constants/refeferenceData/relationships');
const coverSlider = require('../../constants/refeferenceData/coverAmount');

const CoverDetailsSideDrawer = ({ toggleEditCover }) => {

    const { isEditCoverDrawerOpen, toggleEditCoverDrawer, coverMemberList, updateObjectInArrayCoverMember, funeralData } = useContext(DigitalFuneralContext);
   
    
    let CM = coverMemberList[coverMemberList.findIndex((obj) => obj.id == sessionStorage.getItem('coverMemberId'))];
    const [coverMember, setCoverMember] = useState(CM)

    const handleChange = (e) => {
        const value = e.detail;
        const input = e.target.name;
        let formattedValue = value;

        if(input === 'coverAmt'){
            setCoverMember({ ...coverMember, coverAmt: value, coverAmtPremium: getFuneralPremium(value, coverMember.age) })
        }else if (input === 'birth_date') {
            const _age = getAgeValue(value);
            console.log('AGE::', _age);
            if(_age < 0 || _age > 65){
                setCoverMember({...coverMember, birth_date: null})
                return;
                // && getAgeValue(value) <= 65   
            }
            setCoverMember({...coverMember, birth_date: e.detail, age: getAgeValue(e.detail)})
            return;
        }else{
            setCoverMember({ ...coverMember, [input]: formattedValue });        
        }  
    };

    const id = sessionStorage.getItem('coverMemberId');
    const saveCoverMember = () => {
        // console.log(parseFloat(coverMember?.coverAmt), '====', parseFloat(funeralData?.schemeDetails?.coverAmt))
        if(parseFloat(coverMember?.coverAmt) > parseFloat(funeralData?.schemeDetails?.coverAmt)){
            alert(`Cover amount can't be more than ₵${funeralData?.schemeDetails?.coverAmt}`)
            return;
        }
        updateObjectInArrayCoverMember(coverMemberList, { id, ...coverMember });
        sessionStorage.removeItem('coverMemberId')
        toggleEditCover()
        toggleEditCoverDrawer();
    };

    const dataToCheck = () => {
        return {
            relationship: coverMember?.relationship,
            coverAmt: coverMember?.coverAmt,
            first_name: coverMember?.first_name, 
            last_name: coverMember?.last_name,
            birth_date: coverMember?.birth_date,
        }
    }


    return (
        <OmSideDrawer
            onOnSideDrawerClosed={() => {
                toggleEditCoverDrawer();
            }}
            open={isEditCoverDrawerOpen}
        >
            <div slot="side-drawer-content">
                <OmApplicationBeneficiaryDetails>
                    <div slot="om-application-beneficiary-details">
                        <div>
                            <h5 className="no-top-margin side-drawer-header" id="beneficiaries-side-drawer">
                                <strong>Edit Family Member </strong>
                            </h5>

                            <OmFormInputFieldWrapper
                                label="First name"
                                id="first_name"
                                name="first_name"
                                placeholder="eg John"
                                labelAlwaysShow={true}
                                required
                                formId="coverMemberForm"
                                size="full"
                                align="left"
                                type="text"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={coverMember?.first_name}
                                state={isInputValid(coverMember?.first_name)}
                                errorMessage={isInputValid(coverMember?.first_name) === 'error' ? 'Please enter first  name' : undefined }
                                onPersist={(e) => handleChange(e)}
                            />

                            <OmFormInputFieldWrapper
                                label="Last name"
                                id="last_name"
                                name="last_name"
                                placeholder="eg Doe"
                                labelAlwaysShow={true}
                                required
                                formId="coverMemberForm"
                                size="full"
                                align="left"
                                type="text"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={coverMember?.last_name}
                                state={isInputValid(coverMember?.last_name)}
                                errorMessage={isInputValid(coverMember?.last_name) === 'error' ? 'Please enter surname  name' : undefined }
                                onPersist={(e) => handleChange(e)}
                            />
                            
                            <OmFormDropdownFieldWrapper
                                id="Relationship"
                                name="Relationship"
                                useNativeMobileDropdown
                                required
                                size="full"
                                formId="coverMemberForm"
                                placeholder="Relationship"
                                align="left"
                                type="text"
                                value={coverMember?.relationship}
                                state={isInputValid(coverMember?.relationship)}
                                errorMessage={ isInputValid(coverMember?.relationship) === 'error' ? 'Please select relationship' : undefined }
                                options={
                                    referenceRelationship &&
                                    JSON.stringify(
                                        referenceRelationship.RELATIONSHIPS.map((option) => ({
                                            value: option.fullcode,
                                            label: option.description,
                                        }))
                                    )
                                }
                                onOnSelect={(e) => setCoverMember({...coverMember, relationship: e.detail.value }) }
                            />

                            <OmdsDatePickerField
                                label="Date of birth"
                                name='birth_date'
                                value={coverMember?.birth_date}
                                hasError={isInputValid(coverMember?.birth_date) === 'error'}
                                onPersist={(e) => handleChange(e)}
                                // onPersist={ (e) => setBeneficiary({...beneficiary, birth_date: e.detail,}) }
                                placeholder="e.g. 1980-05-05">
                                {isInputValid(coverMember?.birth_date) === 'error' ? (<span slot="hint-text">{'Please select date of birth from calender'}</span>) : undefined}
                            </OmdsDatePickerField>
                            <small>Age can't be more than 65 years</small>

                            <p>Cover Amount: </p>
                            <OmSlider
                                stepString={JSON.stringify(coverSlider.COVER_SLIDER_AMOUNTS)}
                                minLabel="₵2,000"
                                maxLabel="₵20,000"
                                name="coverAmt"
                                value={coverMember?.coverAmt}
                                valuePrefix="₵"
                                onSliderChange={(e) => handleChange(e)}
                            />
                            <small>Cover amount can't be more than ₵{funeralData?.schemeDetails?.coverAmt}</small>

                            <OmApplicationSummaryDetailsCard style={{borderTop:'2px solid #4db152',padding: '5px'}}>
                                <div slot="content" style={{display: 'flex', justifyContent: 'space-around', padding: '5px'}}>
                                    <div className="">
                                        <h5><strong>₵{coverMember?.coverAmt}</strong></h5>
                                        <p>COVER AMOUNT</p>
                                    </div>
                                    <div className="">
                                        <h5><strong>₵{getFuneralPremium(coverMember?.coverAmt, coverMember?.age)}</strong></h5>
                                        <p>TOTAL MONTHLY PREMIUM</p>
                                    </div>
                                </div>
                            </OmApplicationSummaryDetailsCard>


                        </div>

                        <div className="action-buttons">
                            <OmButton
                                className="cancel-button"
                                type="text"
                                size="small"
                                text="CANCEL"
                                onClick={() => toggleEditCoverDrawer()}
                            />

                            <OmButton
                                disabled={!areAllInputsInvalid(dataToCheck())}
                                className="save-button"
                                type="primary"
                                size="small"
                                text="UPDATE QUOTE"
                                onClick={() => saveCoverMember()}
                            />
                        </div>
                    </div>
                </OmApplicationBeneficiaryDetails>
            </div>
        </OmSideDrawer>
    );
};

export default CoverDetailsSideDrawer;
