import React, { useContext } from 'react';
import {OmRadioButtonGroup} from '@om/component-library-react';
import { DigitalFuneralContext } from '../../context/DigitalFuneralContext';
import { getAgeValue, getHospitalCashValue } from '../../../../utils/digital-funeral-utils/util';

const PolicyDetailFirstPage = () => {

    const { funeralData, updateFuneralData } = useContext(DigitalFuneralContext);

    const handleChange = (e) => {
        updateFuneralData({ hasAdditionalBenefit: e.detail }, 'schemeDetails')
        if(e.detail === 'Yes'){
            updateFuneralData({ cashBenefit: getHospitalCashValue(getAgeValue(funeralData?.schemeDetails?.birth_date)) }, 'schemeDetails')
        }else{
            updateFuneralData({ cashBenefit: 0 }, 'schemeDetails')
        }
    }

    return (
        <div className="mvest-scheme-details-page" id='f_policy_detail_1'>
            <div className="mvest-scheme-details-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">Policy Details.</h5>
                <p>Here are the policy details of the Ekyire Asem plan</p>

                <OmRadioButtonGroup
                    id="additional_cash_benefit"
                    vertical={true}
                    groupName="additional_cash_benefit"
                    value={funeralData?.schemeDetails?.hasAdditionalBenefit}
                    onOnSelection={(e) => handleChange(e)}
                    label="Would you like to include an additional cash benefit of 100 cedis as part of your plan for hospitalization coverage?"
                    labelAlwaysShow={true}>
                    <ul slot="list-items">
                        <li value="Yes">Yes</li>
                        <li value="No">No</li>
                    </ul>
                </OmRadioButtonGroup>
                <p>Selecting Yes would add GHC{getHospitalCashValue(getAgeValue(funeralData?.schemeDetails?.birth_date))} to your monthly premium!</p>
            </div>
        </div>
    );
};

export default PolicyDetailFirstPage;