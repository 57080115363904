export const PAGE_INDICES = {
    PERSONAL_DETAILS: 0,
    POLICY_BENEFIT: 1,
    POLICY_COVER: 2,
    BENEFICIARIES: 3,
    BENEFICIARIES_SPLIT: 4,
    REVIEW_APPLICATION: 5,
    SUCCESS: 6,
};

export const PROGRESS_BAR_STEPS = [
    'Personal Details',
    'Policy Details',
    'Beneficiary',
    'Confirmation',
].join('|');
