import { flattenObject, objectArrayToFormData, objectToFormData, getSLAMSBaseURL,relationshipAcronym, getEmergentPayCredentials, getAgentsReferential, getIDReferential, OM_API_BASE_URL } from '../../../../ghana/utils/digital-funeral-utils/util';

const generateBearerToken = async (clientId, clientSecret) => {
    try {
        const response = await fetch(
            `${getSLAMSBaseURL()}/oldmutual_dms_api/auth/tokenRequest?client_id=${clientId}&client_secret=${clientSecret}`,
            {
                method: 'POST',
                headers: {
                    // 'Access-Control-Allow-Origin': process.env.GATSBY_ENVIRONMENT_NAME === 'qa' ? '*' : 'https://www.oldmutual.com.gh'
                },
            }
        );

        const data = await response.json();
        return data?.access_token;
    } catch (error) {
        console.error('Error generating bearer token:', error);
        throw error;
    }
};


export const sendClientRegistration = async (clientData) => {
    try {
        const clientId = getEmergentPayCredentials().client_id;
        const clientSecret = getEmergentPayCredentials().client_secret;
    
        const token = await generateBearerToken(clientId, clientSecret);
        const payload = {
            surname : clientData?.last_name,
            othernames : clientData?.first_name,
            date_of_birth : clientData?.birth_date,
            gender : clientData?.gender == 'Male' ? 'M' : 'F',
            pay_mode : '108',
            email : clientData?.email_address,
            mobile : clientData?.mobile_number,
            id_type : getIDReferential(clientData?.idType),
            id_number : clientData?.id_number,
            sum_assured : clientData?.coverAmt,
            includeHospitalCash : clientData?.hasAdditionalBenefit == 'Yes' ? 1 : 0,
            HospitalCashSumAssured : clientData?.hasAdditionalBenefit == 'Yes' ? 1 : 0,
            telco : 'MO511',
            momo_no : clientData?.mobile_number,
            policyCommencementDate : new Date().toISOString().split('T')[0],
        }
        if(clientData?.agentCode){
            payload.agent_no = getAgentsReferential(clientData?.agentCode)
        }
        const clientRegistartionData = flattenObject(payload)
        // console.log("clientReg::",clientRegistartionData)
   
        // console.log("...travel insurance registeration init...");
        const response = await fetch(
            `${getSLAMSBaseURL()}/oldmutual_dms_api/api/dfp/newProposal`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    // 'Access-Control-Allow-Origin': process.env.GATSBY_ENVIRONMENT_NAME === 'qa' ? '*' : 'https://www.oldmutual.com.gh'
                },
                body: objectToFormData(clientRegistartionData),
            }
        );

        const data = await response.json();
        // console.log("...registered client");
        data._token = token;
        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};


export const sendClientBeneficiaries = async (token, beneficiaries, proposal_no) => {
    try {
   
        //====== re-arrange beneficiaries data =======
        let _newBeneficiaries = [];
        beneficiaries.map((b, i) => {
            let p = {
                'id': i,
                'names': `${b.firstname} ${b.OtherName}`,
                'relationship': relationshipAcronym(b.Relationship),
                'perc_alloc': b.perc_alloc,
                'birth_date': b.birth_date,
                'gender': b.gender == 'Male' ? 'M' : 'F',
                'telephone': b.MobileNumber,
                'IsFlaggedForSMS': '1',
            }
            _newBeneficiaries.push(p)
        })
        // console.log("New Beneficiary::",_newBeneficiaries)

        const clientBeneficiariesData = new FormData();
        _newBeneficiaries.forEach((_newBeneficiaries, index) => {
            for (let key in _newBeneficiaries) {
                key != 'id' && clientBeneficiariesData.append(`beneficiaries[${index}][${key}]`, _newBeneficiaries[key]);
            }
        });

        // clientBeneficiariesData.append('names', _newBeneficiaries[0].OtherNames + ' ' + _newBeneficiaries[0].Surname)
        // clientBeneficiariesData.append('relationship', _newBeneficiaries[0].relationship)
        // clientBeneficiariesData.append('perc_alloc', _newBeneficiaries[0].perc_alloc)
        // clientBeneficiariesData.append('birth_date', _newBeneficiaries[0].birth_date)
        // clientBeneficiariesData.append('gender', _newBeneficiaries[0].gender)
        // clientBeneficiariesData.append('IsFlaggedForSMS', '1')
        // clientBeneficiariesData.append('telephone', _newBeneficiaries[0].MobileNumber)
        clientBeneficiariesData.append('proposal_no', proposal_no)
        // console.log("clientBeneficiariesData::",clientBeneficiariesData)
   
        // console.log("...sending beneficiaries init...");
        const response = await fetch(
            `${getSLAMSBaseURL()}/oldmutual_dms_api/api/dfp/set-multiple-beneficiaries`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    // 'Access-Control-Allow-Origin': process.env.GATSBY_ENVIRONMENT_NAME === 'qa' ? '*' : 'https://www.oldmutual.com.gh'
                },
                body: clientBeneficiariesData,
            }
        );

        const data = await response.json();
        // console.log("clientBeneficiariesData data response::",data);

        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};

export const sendClientCoverMember = async (token, coverMember, proposal_no) => {
    try {

        let _newFamilyMembers = [];
        coverMember.map((b, i) => {
            let p = {
                'id': i,
                'names': `${b.first_name} ${b.last_name}`,
                'relationship': relationshipAcronym(b.relationship),
                'date_of_birth': b.birth_date,
                'gender': b.gender == 'Male' ? 'M' : 'F',
                'sum_assured': b.coverAmt
            }
            _newFamilyMembers.push(p)
        })

        const clientFamilyMemberData = new FormData();

        _newFamilyMembers.forEach((_newFamilyMember, index) => {
            for (let key in _newFamilyMember) {
                key != 'id' && clientFamilyMemberData.append(`funeral_members[${index}][${key}]`,  _newFamilyMember[key]);
            }
        });

        clientFamilyMemberData.append('proposal_no', proposal_no)
        // clientFamilyMemberData.append('names', `${coverMember?.first_name} ${coverMember?.last_name}`)
        // clientFamilyMemberData.append('date_of_birth', coverMember?.birth_date)
        // clientFamilyMemberData.append('sum_assured', coverMember?.coverAmt)
        // clientFamilyMemberData.append('gender', coverMember?.gender == 'Male' ? 'M' : 'F')
        // clientFamilyMemberData.append('relationship', relationshipAcronym(coverMember?.relationship))
        // console.log("clientBeneficiariesData::",clientBeneficiariesData)
   
        // console.log("...sending beneficiaries init...");
        const response = await fetch(
            `${getSLAMSBaseURL()}/oldmutual_dms_api/api/dfp/set-multiple-funeral-members`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    // 'Access-Control-Allow-Origin': process.env.GATSBY_ENVIRONMENT_NAME === 'qa' ? '*' : 'https://www.oldmutual.com.gh'
                },
                body: clientFamilyMemberData,
            }
        );

        const data = await response.json();
        // console.log("clientBeneficiariesData data response::",data);

        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};

export const updatePaymentStatusForQA = async (policy_no) => {

    const clientId = getEmergentPayCredentials().client_id;
    const clientSecret = getEmergentPayCredentials().client_secret;
    const token = await generateBearerToken(clientId, clientSecret);

    const response = await fetch(
        `${getSLAMSBaseURL()}/oldmutual_dms_api/api/policy/updatePaymentStatus?proposal_no=${policy_no}`,
        {
            method: 'POST',
            headers: {Authorization: `Bearer ${token}`, }
        }
    );
    if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
        // update endpoint to STATUS = PAID
        console.log('UPDATE PAYMENT::',response);    
    }
    return response.json();
}

export const checkIfPolicyIsPaid = async (policy_no) => {
    try {

        const acessToken = await generateBearerToken(getEmergentPayCredentials().client_id, getEmergentPayCredentials().client_secret);

        const response = await fetch(
            `${getSLAMSBaseURL()}/oldmutual_dms_api/api/policy/get-proposal-payment-status?proposal_no=${policy_no}`,
            {
                method: 'GET',
                headers: {Authorization: `Bearer ${acessToken}`, }
            }
        );

        const data = await response.json();

        if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
            // update endpoint to STATUS = PAID
            await updatePaymentStatusForQA(acessToken, policy_no);
        }

        return data;

    } catch (error) {
        console.error('Request Error:', error);
    }
}

export const getAgentNamesFromSlams = async () => {
    try {

        const acessToken = await generateBearerToken(getEmergentPayCredentials().client_id, getEmergentPayCredentials().client_secret);

        const response = await fetch(
            `${getSLAMSBaseURL()}/oldmutual_dms_api/api/policy/getAllAgents`,
            {
                method: 'GET',
                headers: {Authorization: `Bearer ${acessToken}` }
            }
        );

        const data = await response.json();

        return data.Agents;

    } catch (error) {
        console.error('Request Error:', error);
    }
}

// Hubspot creating a contact
export const hubspotCreateContact = async (payload) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/create-contact?email=${payload.email}&firstname=${payload.firstname}&lastname=${payload.lastname}&phone=${payload.phone}&hubspot_owner_id=${payload.hubspot_owner_id}`,
            {
                method: 'POST',
            }
        );

        const data = await response.json();
        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot updating a contact
export const hubspotUpdateContact = async (payload, contactId) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/update-contact/${contactId}?email=${payload.email}&firstname=${payload.firstname}&lastname=${payload.lastname}&phone=${payload.phone}&hubspot_owner_id=${payload.hubspot_owner_id}`,
            {
                method: 'PATCH',
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot get a contact by Email
export const hubspotGetContact = async (email) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/get-contact/${email}`,
            {
                method: 'GET',
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot creating a deal
export const hubspotCreateDeal = async (payload) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/create-deal?amount=${payload.amount}&closedat=${payload.closedate}&dealname=${payload.dealname}&pipeline=${payload.pipeline}&dealstage=${payload.dealstage}&hubspot_owner_id=${payload.hubspot_owner_id}&dealtype=${payload.dealtype}&plan_name=${payload.plan_name}&source_of_deduction=${payload.source_of_deduction}&proposal_policy_number=${payload.proposal_policy_number}`,
            {
                method: 'POST',
            }
        );
        const data = await response.json();
        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot updating a deal
export const hubspotUpdateDeal = async (payload, dealId) => {
    try {
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/update-deal/${dealId}`,
            {
                method: 'PATCH',
                body: JSON.stringify(payload),
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot assiign a contact to a deal
export const hubspotAttachContactToDeal = async (dealId, contactId) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/assign-contact-to-deal/${dealId}/${contactId}`,
            {
                method: 'PUT',
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};