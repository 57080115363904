module.exports.ID_TYPES = [
    { fullcode: 'Ghana Card', description: 'Ghana Card' },
    {
        fullcode: 'Voter’s card',
        description: 'Voter’s card',
    },
    // {
    //     fullcode: 'Passport',
    //     description: 'Passport',
    // },
    // { fullcode: "Driver’s license", description: "Driver’s license" },
    // {
    //   fullcode: "SSNIT",
    //   description: "SSNIT",
    // },
    // {
    //   fullcode: "Other",
    //   description: "Other",
    // },
]
