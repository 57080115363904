import WebComponentsConfig from '../components/WebComponentsConfig';
import React from 'react';
import CallMeBackSideDrawer from '../components/sideDrawers/CallMeBackSideDrawer';
import { OmApplicationPageBgColor } from '@om/component-library-react';
import QualifyingCheckCallMeBack from '../components/sideDrawers/qualifyingCheckCallMeBack/QualifyingCheckCallMeBack';

const ApplicationLayout = ({
    theme,
    hasBackground,
    aside,
    children,
}) => {
    return (
        <WebComponentsConfig themeContent={theme}>
            <OmApplicationPageBgColor hasBackground={hasBackground}>
                <svg
                    slot="bg-svg-1"
                    width="360"
                    height="1200"
                    viewBox="0 0 400 1200"
                    fill="#F1F2F2"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 990.938L208.572 792.747L417.11 594.746H208.572L417.11 396.575L625.464 198.383L834.236
                                     0H1042.97L834.236 198.383L625.464 396.575H834.236H1042.97L1248 201.359V399.53L1042.97
                                     594.746L834.236 792.747V594.746H625.464L417.11 792.747L208.572 990.938L0 1189.13V990.938ZM418.667
                                     962.027L626.642 763.924L835.018 962.027L1043.34 763.924L1248 569.179V767.281L1043.34 962.027L835.018
                                     1160.13L626.642 962.027L418.667 1160.13H210.525L418.667 962.027Z"
                        fill="#F1F2F2"/>
                </svg>
                <svg
                    slot="bg-svg-2"
                    width="400"
                    height="1200"
                    viewBox="0 0 400 1200"
                    fill="#F1F2F2"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 990.938L208.572 792.747L417.11 594.746H208.572L417.11 396.575L625.464 198.383L834.236
                                0H1042.97L834.236 198.383L625.464 396.575H834.236H1042.97L1248 201.359V399.53L1042.97
                                594.746L834.236 792.747V594.746H625.464L417.11 792.747L208.572 990.938L0 1189.13V990.938ZM418.667
                                962.027L626.642 763.924L835.018 962.027L1043.34 763.924L1248 569.179V767.281L1043.34 962.027L835.018
                                1160.13L626.642 962.027L418.667 1160.13H210.525L418.667 962.027Z"
                        fill="#F1F2F2"/>
                </svg>
                <div slot="content">
                    {children}
                    <aside>
                        {aside}
                        <CallMeBackSideDrawer />
                        <QualifyingCheckCallMeBack />
                    </aside>
                </div>

            </OmApplicationPageBgColor>
        </WebComponentsConfig>
    );
};

export default ApplicationLayout;
