import React from 'react';
import { OmApplicationPage } from '@om/component-library-react';
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import ApplicationWizardHeader from '../applications/digital-funeral/ApplicationWizardHeader';
import ApplicationWizardFooter from '../applications/digital-funeral/ApplicationWizardFooter';
import { DigitalFuneralProvider } from '../applications/digital-funeral/context/DigitalFuneralContext';
import Outlet from '../applications/digital-funeral/pages/Outlet';

const DigitalFuneralPage = () => {
    return (
        <DigitalFuneralProvider>
            <ApplicationLayout>
                <OmApplicationPage>
                    <div slot="header">
                        <ApplicationWizardHeader callMeBack={{}} />
                    </div>
                    <div slot="content-no-sidebar">
                        <Outlet />
                    </div>
                    <div slot="footer">
                        <ApplicationWizardFooter />
                    </div>
                </OmApplicationPage>
            </ApplicationLayout>
        </DigitalFuneralProvider>
    );
};

export default DigitalFuneralPage;
