import React, {useContext, useState} from 'react';
import { DigitalFuneralContext } from '../../context/DigitalFuneralContext';
import { OmApplicationButton, OmApplicationSummaryDetailsCard } from '@om/component-library-react';
import CoverDetailsSideDrawer from './CoverDetailsSideDrawer'
import FamilyMemberCard from './FamilyMemberCard';
import RemoveMemberModal from './RemoveMemberModal';
import EditCoverDetailsSideDrawer from './EditCoverDetailsSideDrawer';
import { getTotalFuneralCalculationPremium } from '../../../../utils/digital-funeral-utils/util';
import { PAGE_INDICES } from '../../constants/routing';

const PolicyDetailSecondPage = () => {

    const { funeralData, toggleCoverDrawer, setPolicyCoverId, coverMemberList } = useContext(DigitalFuneralContext);

    const clickHandler = () => {
        setPolicyCoverId();
        toggleCoverDrawer();
    };

    return (
        <div className='ekyire-asem-main' id='f_policy_detail_2'>
            <div className="mvest-scheme-details-page">
                <div className="mvest-scheme-details-page-wrapper">
                    <h5 className="heading medium" id="heading-anchor">Now add the details of the people you want to cover.</h5>
                
                    <PolicyCoverList />
                
                    {coverMemberList.length < 2 ?
                        <OmApplicationButton
                            text="ADD FAMILY MEMBER"
                            iconName="add"
                            onButtonClicked={() => clickHandler()}
                        />
                        : ''}
                </div>
                <CoverDetailsSideDrawer />
            </div>
            <div className='half-width'>
                <OmApplicationSummaryDetailsCard style={{borderTop:'2px solid #71A14D'}}>
                    <div slot="content" style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div className="">
                            <h5><strong>Your total funeral cover could cost:</strong></h5>
                            <p>Total monthly premium</p>
                        </div>
                        <div className="">
                            <h4>GH₵ {getTotalFuneralCalculationPremium(funeralData?.schemeDetails?.coverAmtPremium, coverMemberList, funeralData?.schemeDetails?.cashBenefit)} p/m</h4>
                        </div>
                    </div>
                </OmApplicationSummaryDetailsCard>
            </div>
        </div>
    );
};

export default PolicyDetailSecondPage;


const PolicyCoverList = () => {

    const { funeralData, coverMemberList, toggleEditCoverDrawer, setRemoveId, navigateTo} = useContext(DigitalFuneralContext);
    
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEdit, setIsOpeEdit] = useState(false);

    const editHandler = (id) => {
        sessionStorage.setItem('coverMemberId', id)
        setIsOpeEdit(true);
        toggleEditCoverDrawer();
    };

    const removeHandler = (id) => {
        setRemoveId(id);
        setIsOpen(true);
    };
    
    return (
        <div>
            <FamilyMemberCard 
                member={1331}
                hasDetails={true}
                canRemove={false}
                description={'Myself'}
                fullName={`${funeralData?.schemeDetails?.first_name} ${funeralData?.schemeDetails?.last_name}`}
                coverAmount={funeralData?.schemeDetails?.coverAmt}
                monthlyPremium={funeralData?.schemeDetails?.coverAmtPremium}
                editCoverMemberHandler={() => navigateTo(PAGE_INDICES.PERSONAL_DETAILS)}
            />

            {isOpen ? <RemoveMemberModal /> : ''}

            {isOpenEdit ? <EditCoverDetailsSideDrawer toggleEditCover={()=>setIsOpeEdit(false)}/> : ''}

            {coverMemberList.map(member => 
                <FamilyMemberCard
                    key={member?.id} 
                    member={member?.id}
                    hasDetails={true}
                    canRemove={true}
                    description={member?.relationship}
                    fullName={`${member?.first_name} ${member?.last_name}`}
                    coverAmount={member?.coverAmt}
                    monthlyPremium={member?.coverAmtPremium}
                    editCoverMemberHandler={()=> editHandler(member?.id)}
                    removeMemberHandler={()=> removeHandler(member?.id)}
                />
            )}
            
        </div>
    )
}