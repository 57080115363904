import React, { useContext } from 'react';
import './ReviewPage.scss';
import { DigitalFuneralContext } from '../../context/DigitalFuneralContext';
import { PAGE_INDICES } from '../../constants/routing';
import { OmApplicationReviewSection,OmLabeledValueSummary,OmCheckBox,OmFormError,OmApplicationSummaryDetailsCard,OmModalLoader } from '@om/component-library-react';
import { OmdsCell,OmdsGrid } from '@mom-omds/react-design-system-components/dist/generated/components';
import { getTotalFuneralCalculationPremium } from '../../../../utils/digital-funeral-utils/util';
import SelectTelco from '../modal/SelectTelco';

const ReviewPage = () => {
    const {
        navigateTo,
        funeralData,
        beneficiaryListDataFuneral,
        hasAcceptedTermsAndCondition,
        acceptTermsAndCondition,
        coverMemberList,
        togglePaymentModal,
        paymentModal
    } = useContext(DigitalFuneralContext);

    const joinName = (nameArray) => { return nameArray.join(' ') };

    const toggle = () => { acceptTermsAndCondition();  };

    return (
        <div id='f_confirmation'>
            <OmdsGrid isFixed>
                <OmdsCell span="row" span-md="7">
                    <div>
                        <h4 className="page-heading sessioncamhidetext">Lastly, please confirm your details</h4>
                        <div>
                            <OmApplicationReviewSection titleHeader="Personal Details">
                                <span
                                    slot="edit-link"
                                    onClick={() => navigateTo(PAGE_INDICES.PERSONAL_DETAILS)}
                                >
                                    <h6 className="small">
                                        <strong>EDIT</strong>
                                    </h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Full name"
                                                value={joinName([
                                                    funeralData?.schemeDetails?.first_name,
                                                    funeralData?.schemeDetails?.last_name,
                                                ])}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Date of birth"
                                                value={funeralData?.schemeDetails?.birth_date}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Gender"
                                                value={funeralData?.schemeDetails?.gender}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Email"
                                                value={funeralData?.schemeDetails?.email_address}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Mobile number"
                                                value={funeralData?.schemeDetails?.mobile_number}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Payment Frequency"
                                                value={funeralData?.schemeDetails?.payment_frequency}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="ID Type"
                                                value={funeralData?.schemeDetails?.idType}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="ID Number"
                                                value={funeralData?.schemeDetails?.id_number}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>

                            <OmApplicationReviewSection titleHeader="Policy Details & Lives Covered">
                                <span slot="edit-link" onClick={() => navigateTo(PAGE_INDICES.POLICY_BENEFIT)}>
                                    <h6 className="small"><strong>EDIT</strong></h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="10">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Hospital Cash Benefits of ₵100"
                                                value={funeralData?.schemeDetails?.hasAdditionalBenefit}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label={'Name'}
                                                value={`${funeralData?.schemeDetails?.first_name} ${funeralData?.schemeDetails?.last_name}`}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label={'Relationship'}
                                                value={'Myself'}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    {coverMemberList.map((member, index) =>
                                        <OmdsGrid key={index}>
                                            <OmdsCell span="row" span-md="6">
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    label={'Name'}
                                                    value={`${member?.first_name} ${member?.last_name}`}
                                                />
                                            </OmdsCell>
                                            <OmdsCell span="row" span-md="7.." key={index}>
                                                <OmLabeledValueSummary
                                                    isSensitive={true}
                                                    label={'Relation'}
                                                    value={member?.relationship}
                                                />
                                            </OmdsCell>
                                        </OmdsGrid>
                                    )}
                                </div>
                            </OmApplicationReviewSection>

                            {beneficiaryListDataFuneral && beneficiaryListDataFuneral.length > 0 && (
                                <OmApplicationReviewSection titleHeader="Beneficiary Details">
                                    <span
                                        slot="edit-link"
                                        onClick={() => navigateTo(PAGE_INDICES.BENEFICIARIES)}
                                    >
                                        <h6 className="small">
                                            <strong>EDIT</strong>
                                        </h6>
                                    </span>
                                    {beneficiaryListDataFuneral.length > 1 ? (
                                        <div slot="section-body">
                                            {beneficiaryListDataFuneral.map((beneficiary, index) => {
                                                return (
                                                    <div key={index}>
                                                        <OmdsGrid>
                                                            <OmdsCell span="row" span-md="6">
                                                                {' '}
                                                                <OmLabeledValueSummary
                                                                    isSensitive={true}
                                                                    className="beneficiary-name"
                                                                    label="Full name"
                                                                    value={joinName([
                                                                        beneficiary?.firstname,
                                                                        beneficiary?.OtherName,
                                                                    ])}
                                                                />
                                                            </OmdsCell>
                                                            <OmdsCell span="row" span-md="7..">
                                                                {' '}
                                                                <OmLabeledValueSummary
                                                                    className="beneficiary-split"
                                                                    label="Split"
                                                                    value={beneficiary?.perc_alloc + '%'}
                                                                />
                                                            </OmdsCell>
                                                        </OmdsGrid>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div slot="section-body">
                                            <OmdsGrid>
                                                <OmdsCell span="row" span-md="6">
                                                    <OmLabeledValueSummary
                                                        isSensitive={true}
                                                        className="beneficiary-name"
                                                        label="Full name"
                                                        value={joinName([
                                                            beneficiaryListDataFuneral[0]?.firstname,
                                                            beneficiaryListDataFuneral[0]?.OtherName,
                                                        ])}
                                                    />
                                                </OmdsCell>
                                            </OmdsGrid>
                                        </div>
                                    )}
                                </OmApplicationReviewSection>
                            )}
                        </div>
                    </div>
                </OmdsCell>
                <OmdsCell span="row" span-md="8..">
                    <OmApplicationSummaryDetailsCard>
                        <div slot="content">
                            <p><strong>Product summary</strong></p>
                            <h1 style={{ marginHeight: '10px' }}>GH₵ {getTotalFuneralCalculationPremium(funeralData?.schemeDetails?.coverAmtPremium, coverMemberList, funeralData?.schemeDetails?.cashBenefit)}p/m</h1>
                            <p>Total monthly payment</p>

                            <div style={{ marginTop: '3rem' }} className="check-box-container">
                                <OmCheckBox
                                    id="terms-and-conditions-checkbox"
                                    checked={hasAcceptedTermsAndCondition}
                                    onOnCheckBoxChanged={() => toggle()}
                                >
                                    <p slot="text-slot">
                                        I accept the&nbsp;
                                        <a target="_blank" rel="noopener noreferrer" style={{color: '#50b848'}}
                                            href="https://www.oldmutual.com.gh/personal/ekyire-asem-plan/terms-and-conditions/">
                                        terms & conditions.
                                        </a>
                                    </p>
                                </OmCheckBox>
                                <br />
                                <OmFormError message={hasAcceptedTermsAndCondition === true ? undefined : 'Please select checkbox before you can continue'} />
                            </div>
                        </div>
                    </OmApplicationSummaryDetailsCard>
                </OmdsCell>
            </OmdsGrid>
            <OmModalLoader
                slot="loading-modal"
                loaderText="Please wait while we submit your data..."
                verticalAlignMiddle={true}
                open={funeralData?.schemeDetails?.loading}
            />

            <SelectTelco open={paymentModal} onCloseHandler={() => togglePaymentModal()} />
        </div>
    );
};

export default ReviewPage;
