import React, { useContext } from 'react';
import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';
import OmButton from '../../../../../../../components/forms/OmButton';
import { DigitalFuneralContext } from '../../context/DigitalFuneralContext';

const RemoveBeneficiaryModal = () => {
    const { idToRemove, deleteBeneficiary } = useContext(DigitalFuneralContext);
    return (
        <OmApplicationPopupModal name="removeModal" dismissible exitable>
            <div slot="content">
                <OmIcon image-url="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltc34f1c6e32fb7291/5ec3d2d2a4bead4101d1941f/TrashSVG.svg" />
                <p>Are you sure you want to remove this beneficiary?</p>
            </div>
            <div slot="button">
                <OmButton
                    className="cancel-button"
                    type="primary"
                    size="small"
                    text="YES, REMOVE"
                    onClick={() => deleteBeneficiary(idToRemove)}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default RemoveBeneficiaryModal;
